<script setup lang="ts">
  import { API_MODX_URL } from '~/EastclinicVueApi/config';
  import { postToServer } from '~/EastclinicVueApi/util/UseFetchToServer';
  import type NewsResponse from '~/interfaces/api/newsResponse';

  const fetchNews = async () => {
    const request = {
      action: 'getSmiLinks',
      component: 'Reviews',
      page: 1,
      perPage: 9
    };
    const res = await postToServer(API_MODX_URL, request) as NewsResponse;
    return res.object.items;
  };
  const { data } = await useAsyncData('smiNews', fetchNews);
</script>

<template>
  <div v-if="data && data.length > 0" class="main-container-desktop news-container">
    <div class="news-title">
      Мы в СМИ
    </div>
    <ScrollCarousel
      :show-arrows="true"
      item-gap="20px"
      arrows-pos="-15px"
      :item-width="397"
    >
      <NewsItem
        v-for="i in data"
        :key="i.source"
        :source="i.source"
        :title="i.header"
        :date="getDMYDate(i.date)"
        :image="i.image"
        :link="i.link"
        :is-carousel="true"
      />
    </ScrollCarousel>
    <div class="main-container news-button-container">
      <NuxtLink :to="{name: 'News'}" class="news-button">
        Посмотреть
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.news-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 80px;
  @media (max-width: 1023px) {
    gap: 32px;
    margin-bottom: 60px;
  }
}
.news-title {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #262633;
  font-family: 'Roboto';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.085px;
  @media (max-width: 1023px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.news-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 56px;
  padding: 0px 20px;
  border-radius: 15px;
  background-color: #2D7FF9;

  text-decoration: none;
  color: white !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.2px;

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 60px;
  }
}
</style>
